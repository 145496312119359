import * as React from 'react'

import Layout from '../layouts/layout'
import sty from '../layouts/styles/index'
import { ContentBox } from 'src/components/Main/ContentBox'
import { Envelope, Mobile, Phone } from 'src/components/Icons'
import { PhoneLink, MailLink } from 'src/components/Footer'


const H3 = props => <sty.Heading3 {...props} textAlign='left' mb={5}/>
const Text = props => <sty.Text {...props} textAlign='left' mb={5} />
const Td = props => <sty.Box {...props} as='td' p={0} pr='8px' />


const AboutPage = () => (
  <Layout seo={{ title: 'Impressum' }}>
    <ContentBox
      src={false}
      title='Impressum'
      subtitle={null}
      textAs={null}
      text={<>
        <H3 children='Kontakt' />
        <Text>
          Alpisol Einblasen GmbH<br />
          Timo Streule<br />
          Schwarzeneggstrasse 13<br />
          CH-9058 Brülisau
        </Text>
        <Text>
          <table>
            <tr>
              <Td children={<sty.Text children={<Phone />} />} />
              <Td children={<PhoneLink tel='+41 71 700 11 34' />} />
            </tr>
            <tr>
              <Td children={<sty.Text children={<Envelope />} />} />
              <Td children={<MailLink mailto='info@alpisol.ch' />} />
            </tr>
          </table>
        </Text>
        <Text>UID: CHE-169.447.475</Text>

        <br/>

        <H3 children='Konzept, Inhalt, Bilder, Design' />
        <Text>
          Timo Streule<br />
          Schwarzeneggstrasse 13<br />
          CH-9058 Brülisau
        </Text>
        <Text>
          <table>
            <tr>
              <Td children={<sty.Text children={<Mobile />} />} />
              <Td children={<PhoneLink tel='+41 79 431 94 47' />} />
            </tr>
            <tr>
              <Td children={<sty.Text children={<Envelope />} />} />
              <Td children={<MailLink mailto='timo.streule@bluewin.ch' />} />
            </tr>
          </table>
        </Text>

        <br/>

        <H3 children='Haftungsausschluss' />
        <Text>
          Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen. Des Weiteren hat der Betreiber der Website keinerlei Einfluss auf die Inhalte und die Gestaltung der verlinkten Seiten.
          <br/><br/>
          Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.
          <br/><br/>
          Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
        </Text>
      </>}
      button={false}
    />
  </Layout>
)

export default AboutPage
